import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Leadingdemand({data}) {
    return (
        <section className="p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.leading_company_body_txt}>
                            <h2>{checkData(data, 'leading_company_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'leading_company_description'))}</div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Leadingdemand;